@media (min-width: 1680px){
  .container{
    max-width: 1680px!important;
  }
  .nav-cont{
    max-width: 1563px;
  }
}
.pageLogo{
  height: 48px;
}
.swap-order-card{
  overflow: hidden;
}
.flex{
  display: flex;
}
.layer17 {
  height: auto;
  /* background: linear-gradient(135deg, rgba(200, 109, 215, 0) 0%, #3023AE 100%)!important; */
  /* border: 4px solid rgba(141, 88, 204, 1)!important; */
  /* background: url(./img/SketchPng6e740d513fc0cb1e8c1032816b70d249e935037df4216f3f716f2f778558ffc4.png) */
  background:#260E3E!important;
  background-size: 100% auto;
  background-position: left bottom;
  width: 1184px;
  border-radius: 0!important;
  border:0!important;
  border: 4px solid rgba(141, 88, 204, 1)!important;
  position: relative;
 
}
.accpet-btn{
  height: 40px;
  background: url(./images/accept.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.accpet-btn button{
  font-size: 16px;
  font-family: Santral-SemiBold, Santral;
  font-weight: 600;
  color: #F7EFFF;
  width: 100%;
  display: inline-block;
}
.wid100{
  width: 100%;
  background-color:#000000!important;
}
.wid100 .nav-cont{
  max-width:1200px;
  margin: 0 auto;
}
.connect-wrapper{
  /* background: url(./images/accept.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; */
}
.connect-wallet{
  /* background: url(./images/accept.svg); */
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 40px; */
  background: #5A30AE!important;
  height: 40px;
  font-size: 16px;
  font-family: Santral-Medium, Santral;
  font-weight: 500;
  color: #F7EFFF;
  border-radius: 20px!important;
}
.connect-wallet:hover{
  color:#260E3E
}
.error-message{
  font-size: 28px;
  font-family: Santral-SemiBold, Santral;
  font-weight: 600;
  color: #F2F5FF;;
}
.red-color{
  color: #FC1355!important;
  font-weight: normal;
}
.nav-cont{
  height: 88px;
}

.modal1{
background: #34165A!important;
box-shadow: 4px 4px 6px 3px rgba(25, 7, 78, 0.3);
border: 4px solid #8D58CC!important;
font-family: Santral-Book, Santral;
font-weight: normal;
color: #F2F5FF;
width: 400px!important;
z-index: 9999!important;
/* top:120px */
}
.modal2 button{
  width: 100%;
  height: 40px;
  background: #5A30AE ;
  font-size: 16px;
  font-family: Santral-Book, Santral;
  font-weight: normal;
  color: #F2F5FF;
}
.modal1 .close-btn{
  width: 40px;
  height: 40px;
  background: #8D58CC;
  cursor: pointer;
}
.error-card{
  border-radius: 0!important;
  background: RGBA(52, 22, 91, 0.9)!important;

}
.close-btn{
  width: 40px;
  height: 40px;
  background: #8D58CC;
  cursor: pointer;
}
.left-border:before{
  content: '';
  width: 2px;
  height: 40px;
  position: absolute;
  top: -10px;
  left: 0;
  background: #33165ac6;
}
.bg-btn-color{
  background: #5A30AE;
}
.opacity_bg{
  background:rgba(131,179,253,0.3)
}
.webname{
  font-size: 18px;
  font-family: Santral-Medium, Santral;
  font-weight: 500;
  color: #83FFCB;
  position: relative;
}
.webname:after{
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: 0;
  background: #83FFCB;;
}
.h-124{
  min-height:124px!important;
}
.view-wallet-color{
  color: #B6AEC7;
}
.disconect{
  background: #5A30AE;
  border-radius: 20px;

}
.box25 {
  /* background: url(./img/SketchPng6e740d513fc0cb1e8c1032816b70d249e935037df4216f3f716f2f778558ffc4.png)
    100% no-repeat; */
  width: 1176px;
  /* margin: 4px 0 0 4px; */
  background:linear-gradient(135deg, rgba(200, 109, 215, 0) 0%, #422387  100%)!important;
  /* border: 4px solid rgba(141, 88, 204, 1)!important; */
}
.box26 {
  width: 1071px;
  height: 465px;
  margin: 55px 0 0 55px;
}
.section14 {
  width: 1059px;
  height: 28px;
  margin-left: 1px;
}
.outer5 {
  background-color: rgba(110, 56, 155, 1);
  width: 430px;
  height: 2px;
  margin-top: 12px;
}
.word26 {
  width: 85px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(131, 255, 203, 1) !important;
  font-size: 28px !important;
  text-transform: uppercase;
  font-family: 'Santral-SemiBold'!important;
  text-align: center;
  white-space: nowrap;
  line-height: 28px !important;
  margin-left: 70px !important;
  display: block;
}
.outer6 {
  background-color: rgba(110, 56, 155, 1);
  width: 430px;
  height: 2px;
  margin: 12px 0 0 44px;
}
.section15 {
  width: 1060px;
  height: 25px;
  margin-top: 66px;
}
.txt11 {
 
  height: 24px;
  color: rgba(182, 174, 199, 1)!important;
  font-size: 20px!important;
  font-family: Santral-SemiBold!important;
  line-height: 24px!important;
  display: block;
}
.info16 {
  width: 83px;
  height: 25px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 25px;
  margin-left: 203px;
}
.info37 {
  width: 83px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}
.info38 {
  width: 83px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}
.word74 {
  width: 120px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 18px;
  display: block;
  margin: 4px 0 0 12px;
}
.word75 {
  width: 176px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 24px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin-left: 109px;
  display: block;
}
.group8 {
  width: 83px;
  height: 25px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 25px;
  margin-left: 163px;
}
.word76 {
  width: 83px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}
.word77 {
  width: 83px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}
.word78 {
  width: 44px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 18px;
  display: block;
  margin: 4px 0 0 5px;
}
.section16 {
  width: 1069px;
  height: 60px;
  margin-top: 25px;
}
.wrap9 {
  background: #260E3E ;
  border-radius: 30px!important;
  height: 60px;
  border:none!important;

}
.outer13 {
  width: 427px;
  height: 28px;
  margin: 16px 0 0 30px;
}
.info39 {
  width: 58px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 24px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 24px;
  margin-top: 4px;
  display: block;
}
.outer14 {
  width: 79px;
  height: 25px;
  overflow-wrap: break-word;
  font-size: 0;
  text-transform: uppercase;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 3px 0 0 23px;
}
.word79 {
  width: 79px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  text-transform: uppercase;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}
.word80 {
  width: 79px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}
.word81 {
  width: 79px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  text-transform: uppercase;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}
.outer15 {
  background-color: rgba(255, 154, 1, 1);
  border-radius: 30px;
  height: 28px;
  margin-left: 161px;
  width: 55px;
}
.word82 {
  
  height: 20px;
  padding: 0 8px;
  color: rgba(239, 242, 255, 1)!important;
  font-size: 14px;
  font-family: Santral-Medium;
  margin: 2px 0 0 10px;
}
.info40 {
  width: 37px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  display: block;
  margin: 5px 0 0 14px;
}
.layer38 {
  width: 22px;
  height: 23px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng295b0f0daa4caeec5df432e1ac9b428c5a036632a48c1d3ea2db7569c629afc7)
    100% no-repeat;
  margin-top: 19px;
}
.wrap10 {
  background-color: rgba(38, 14, 62, 1);
  border-radius: 30px;
  height: 60px;
  width: 480px;
}
.group9 {
  width: 434px;
  height: 24px;
  margin: 20px 0 0 27px;
}
.txt34 {
  width: 58px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 24px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 24px;
  display: block;
}
.word83 {
  width: 38px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 1px;
  display: block;
}
.img2 {
  width: 1070px;
  height: 1px;
  margin: 20px 0 0px 1px;
}
.section17 {
  width: 1069px;
  height: 61px;
  margin-top: 36px;
}
.TextGroup37 {
  height: 61px;
  width: 104px;
}
.mod14 {
  width: 104px;
  height: 61px;
}
.info41 {
  width: 93px;
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 30px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 30px;
  display: block;
}
.txt35 {
  width: 104px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 13px;
  display: block;
}
.TextGroup38 {
  height: 61px;
  width: 106px;
}
.main8 {
  width: 106px;
  height: 61px;
}
.txt36 {
  width: 99px;
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 30px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 30px;
  margin-left: 7px;
  display: block;
}
.word84 {
  width: 106px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 13px;
  display: block;
}
.section18 {
  height: 55px;
  margin: 25px 0 20px 0px;
  color: #83FFCB;
  font-size: 24px;
  font-family: Santral-SemiBold, Santral;
  font-weight: 600;
  color: #83FFCB;
  background: url(./images/square.svg);
  background-repeat: no-repeat;
  background-position: center top;
}
.word85 {
  width: 50px;
  height: 36px;
  overflow-wrap: break-word;
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: left;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.word86 {
  /* width: 166px; */
  height: 36px;
  overflow-wrap: break-word;
  margin-left: 15px;
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: right;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.section19 {
  height: 60px;
  background:#5643F2 !important;
  width: 400px;
  margin:0 auto;
  border-radius: 50px !important;
}
 .word87{
  width: 94px;
  overflow-wrap: break-word;
  color: #fff!important;
  font-size: 24px!important;
  font-family: 'Santral-Medium' !important;
  text-align: center;
  white-space: nowrap;
  display: block;
  border-radius: 20px !important;
  height: 60px;
  background-color:unset !important;
  /* background:#5643F2 !important; */
 
}
.word87:hover {
  color: rgba(38, 14, 62, 1)!important;
}
.section19:hover{
  background:linear-gradient(135deg, #83FFCB 0%, #82C9FD 100%)!important
  /* //'linear(to-l, #83FFCB, #82C9FD)' */
  /* // */
}
.btn-hover:hover{
  background:linear-gradient(135deg, #83FFCB 0%, #82C9FD 100%)!important;
  color:#260E3E;
  
}
.inputFontsize {
  /* // 上下没有居中的问题 */
  position: relative; 
  top: 2px;
}
.inputFontsize input{
  font-size: 24px!important;
}
/* 放到index.css里面去了 */
/* body{
  background-image:url('./img/SketchPng89196fc5ed7ad42e6cf7ddcbd688ca9793f944db4d6ee1e4e06f16651cba316b.png')!important;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100%;
   */
/* } */
/* #root{
  background-image:url('./img/SketchPnga16fd6e33d65f995feb3054a68a5762dfbf3d380e8f8c1b1c0ff4d59a4d2d350.png')!important;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 100%;
} */
.back-bottom{
  margin-bottom: 280px;
}
.error-card{
  width: 100%;
  height: 100%;
  /* top:20%; */
}
.loading-div{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: RGBA(51, 22, 89, 0.2)
}
.loading-div .modal1 {
  position: relative;
  width: 100% !important;
  box-shadow: none !important;
  border: none !important;
}
.notBegin img{
    width: 170px;
}