.page {
  background-color: rgba(30, 30, 30, 1);
  position: relative;
  width: 1920px;
  height: 2450px;
  overflow: hidden;
}

.box1 {
  background-color: rgba(13, 0, 54, 1);
  height: 2450px;
  width: 1920px;
}

.layer3 {
  height: 955px;
  background: url(./img/SketchPng50b2d866b5fcbabb418758154093365db1c9f63221a896f69fd0a087c5bdd062.png)
    100% no-repeat;
  margin-top: 1495px;
  width: 1920px;
}

.box3 {
  width: 1920px;
  height: 955px;
  background: url(./img/SketchPnga16fd6e33d65f995feb3054a68a5762dfbf3d380e8f8c1b1c0ff4d59a4d2d350.png)
    100% no-repeat;
}

.box4 {
  z-index: 9;
  height: 2450px;
  background: url(./img/SketchPng84ccf17123a30d4d0605e8c7442bdc137603ede4483fafb80ed5410c80fe4603.png)
    100% no-repeat;
  width: 1920px;
  position: absolute;
  left: 0;
  top: -3px;
}

.layer35 {
  width: 1920px;
  height: 2131px;
  margin-top: 35px;
}

.bd21 {
  background-color: rgba(0, 0, 0, 1);
  height: 88px;
  width: 1920px;
}

.section1 {
  width: 1184px;
  height: 48px;
  margin: 20px 0 0 368px;
}

.group13 {
  width: 46px;
  height: 48px;
  background: url(./img/SketchPng7914d625a8170908862535f790dcac0427e8cecaf508f04175660fe224456e79.png)
    0px 0px no-repeat;
}

.word105 {
  width: 112px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(131, 255, 203, 1);
  font-size: 18px;
  font-family: Santral-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  display: block;
  margin: 15px 0 0 88px;
}

.info51 {
  width: 117px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(242, 245, 255, 1);
  font-size: 18px;
  font-family: Santral-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  display: block;
  margin: 15px 0 0 69px;
}

.group14 {
  background-color: rgba(90, 48, 174, 1);
  border-radius: 20px;
  height: 40px;
  width: 240px;
  margin: 4px 0 0 512px;
}

.block9 {
  width: 200px;
  height: 24px;
  margin: 8px 0 0 20px;
}

.outer19 {
  width: 26px;
  height: 24px;
  background: url(./img/SketchPng6877c40e4a7c1095e5a8128fa510aa694a79f7a1b8c80e01bdad6be2a989ff4a.png)
    0px 0px no-repeat;
}

.info52 {
  width: 159px;
  height: 16px;
  overflow-wrap: break-word;
  color: rgba(247, 239, 255, 1);
  font-size: 16px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 16px;
  margin-top: 5px;
  display: block;
}

.layer6 {
  background-color: rgba(87, 69, 241, 1);
  width: 1113px;
  height: 4px;
  margin: 60px 0 0 439px;
}

.layer7 {
  background-color: rgba(38, 14, 62, 1);
  height: 472px;
  border: 4px solid rgba(141, 88, 204, 1);
  width: 1184px;
  /* margin: 6px 0 0 368px; */
}

.group1 {
  height: 464px;
  background: linear-gradient(135deg, #2b0d43 0%, #421d8a 100%);
  width: 1176px;
  /* margin: 4px 0 0 4px; */
}

.main10 {
  width: 1063px;
  height: 364px;
  margin: 55px 0 0 57px;
}

.box14 {
  width: 1063px;
  height: 28px;
}

.layer8 {
  background-color: rgba(110, 56, 155, 1);
  width: 360px;
  height: 2px;
  margin-top: 12px;
}

.word3 {
  width: 269px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(131, 255, 203, 1);
  font-size: 28px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 28px;
  display: block;
}

.layer9 {
  background-color: rgba(110, 56, 155, 1);
  width: 360px;
  height: 2px;
  margin-top: 12px;
}

.main16 {
  width: 962px;
  height: 141px;
  margin: 18px 0 0 46px;
}

.section21 {
  width: 170px;
  height: 141px;
  background: url(./images/phase1.png)
    100% no-repeat;
  background-size: contain;
}

.main15 {
  width: 197px;
  /* height: 122px; */
  margin: 18px 0 0 217px;
}

.wrap14 {
  width: 146px;
  height: 137px;
  background: url(./images/phase3.png)
    100% no-repeat;
  background-size: contain;
  margin: 3px 0 0 232px;
}

.bd17 {
  width: 961px;
  height: 48px;
  margin-top: 25px;
}

.ImageText13 {
  height: 48px;
  width: 200px;
}

.bd18 {
  width: 200px;
  height: 48px;
}

.box27 {
  width: 44px;
  height: 44px;
  background: url(./images/phase1-icon.png)
    0px -2px no-repeat;
  background-size: contain;
  margin-top: 1px;
}

.TextGroup39 {
  height: 48px;
  width: 146px;
}

.layer39 {
  width: 146px;
  height: 48px;
}

.word94 {
  width: 89px;
  overflow-wrap: break-word;
  color: rgba(242, 245, 255, 1);
  font-size: 24px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  display: block;
}

.txt48 {
  width: 146px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 12px;
  font-family: Santral-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 8px;
  display: block;
}

.ImageText14 {
  height: 48px;
  margin-left: 151px;
  width: 242px;
}

.box28 {
  width: 242px;
  height: 48px;
}

.wrap11 {
  width: 44px;
  height: 44px;
  background: url(./images/phase2-icon.png)
    0px -2px no-repeat;
  margin-top: 1px;
  background-size: contain;
}

.TextGroup40 {
  height: 48px;
  width: 188px;
}

.main11 {
  width: 188px;
  height: 48px;
}

.word95 {
  width: 94px;
  overflow-wrap: break-word;
  color: rgba(242, 245, 255, 1);
  font-size: 24px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  display: block;
}

.info46 {
  width: 188px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 12px;
  font-family: Santral-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 8px;
  display: block;
}

.ImageText15 {
  height: 48px;
  margin-left: 210px;
  width: 158px;
}

.wrap12 {
  width: 158px;
  height: 48px;
}

.main12 {
  width: 44px;
  height: 44px;
  background: url(./images/phase3-icon.png)
    0px -2px no-repeat;
  margin-top: 1px;
  background-size: contain;
}

.TextGroup41 {
  height: 48px;
  width: 104px;
}

.main13 {
  width: 104px;
  height: 48px;
}

.info47 {
  width: 93px;
  overflow-wrap: break-word;
  color: rgba(242, 245, 255, 1);
  font-size: 24px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  display: block;
}

.txt49 {
  width: 104px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 12px;
  font-family: Santral-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 8px;
  display: block;
}

.bd19 {
  width: 968px;
  height: 18px;
  margin-top: 24px;
}

.word96 {
  width: 38px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(242, 245, 255, 1);
  font-size: 14px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  display: block;
}

.pic9 {
  width: 200px;
  height: 1px;
  margin: 11px 0 0 24px;
}

.word97 {
  width: 38px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(242, 245, 255, 1);
  font-size: 14px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-left: 89px;
  display: block;
}

.img3 {
  width: 300px;
  height: 1px;
  margin: 11px 0 0 24px;
}

.txt50 {
  width: 38px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(242, 245, 255, 1);
  font-size: 14px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-left: 90px;
  display: block;
}

.img4 {
  width: 103px;
  height: 1px;
  margin: 11px 0 0 24px;
}

.bd20 {
  width: 1063px;
  height: 51px;
  margin-top: 11px;
}

.layer40 {
  width: 85px;
  height: 51px;
}

.group16 {
  background-color: rgba(255, 154, 1, 1);
  height: 26px;
  width: 85px;
}

.info64 {
  width: 11px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(242, 245, 255, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  display: block;
  margin: 3px 0 0 37px;
}

.info48 {
  width: 62px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(242, 245, 255, 1);;
  font-size: 12px;
  font-family: Santral-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 5px;
  display: block;
}

.bd23 {
  background-color: rgba(255, 154, 1, 1);
  height: 26px;
  margin-left: 2px;
  width: 85px;
}

.info65 {
  width: 11px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(242, 245, 255, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  display: block;
  margin: 3px 0 0 37px;
}

.bd24 {
  background-color: rgba(255, 154, 1, 1);
  height: 26px;
  margin-left: 2px;
  width: 86px;
}

.word121 {
  width: 12px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(242, 245, 255, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  display: block;
  margin: 3px 0 0 37px;
}

.ImageText16 {
  height: 51px;
  margin-left: 91px;
  width: 362px;
}

.box29 {
  width: 362px;
  height: 51px;
}

.outer20 {
  height: 26px;
  background: url(./img/SketchPng57c3775c169b0361b4ac28e6c8f85fda31e611481176b18c86d332b827ca23a3.png)
    100% no-repeat;
  width: 362px;
}

.layer43 {
  width: 318px;
  height: 20px;
  margin: 2px 0 0 22px;
}

.word122 {
  width: 7px;

  overflow-wrap: break-word;
  color: rgba(41, 41, 41, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  display: block;
}

.txt58 {
  width: 8px;

  overflow-wrap: break-word;
  color: rgba(41, 41, 41, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  display: block;
}

.info66 {
  width: 8px;

  overflow-wrap: break-word;
  color: rgba(41, 41, 41, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  display: block;
}

.info67 {
  width: 8px;

  overflow-wrap: break-word;
  color: rgba(41, 41, 41, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  display: block;
}

.word123 {
  width: 8px;
 
  overflow-wrap: break-word;
  color: rgba(41, 41, 41, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  display: block;
}

.word124 {
  width: 8px;
  
  overflow-wrap: break-word;
  color: rgba(186, 189, 204, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;

  display: block;
}

.txt59 {
  width: 6px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(186, 189, 204, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  
  display: block;
}

.TextGroup42 {
  height: 20px;
  margin-top: 5px;
  width: 63px;
}

.word104 {
  width: 63px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 12px;
  font-family: Santral-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  display: block;
}

.ImageText17 {
  height: 51px;
  margin-left: 90px;
  width: 260px;
}

.outer17 {
  width: 260px;
  height: 51px;
}

.mod18 {
  width: 260px;
  height: 26px;
  background: url(./img/SketchPng80450c03fa4b593e66fae8e38dcf46b2a4622dca1ce70c16b26b9effaccc0627.png)
    100% no-repeat;
}

.TextGroup43 {
  height: 20px;
  margin-top: 5px;
  width: 63px;
}

.info50 {
  width: 63px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 12px;
  font-family: Santral-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  display: block;
}

.layer14 {
  z-index: 275;
  /* height: 740px; */
  /* background: url(./img/SketchPng6e740d513fc0cb1e8c1032816b70d249e935037df4216f3f716f2f778558ffc4.png) */
    /* 100% no-repeat; */
  width: 1184px;
  position: relative;
  /* margin: 80px 0 0 368px; */
}
.layer14.rct_card {
  background: #260E3E !important;
}

.mod8 {
  width: 1068px;
  height: 40px;
  margin: 55px 0 0 60px;
}

.bd2 {
  background-color: rgba(110, 56, 155, 1);
  width: 260px;
  height: 2px;
  margin-top: 26px;
}

.infoBox1 {
  width: 476px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(131, 255, 203, 1);
  font-size: 28px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 40px;
  margin-left: 44px;
  display: block;
}

.bd3 {
  background-color: rgba(110, 56, 155, 1);
  width: 260px;
  height: 2px;
  margin: 26px 0 0 28px;
}

.mod4 {
  width: 1068px;
  height: 361px;
  margin: 122px 0 0 60px;
}

.mod9 {
  width: 237px;
  height: 244px;
  margin-top: 14px;
}

.TextGroup33 {
  height: 70px;
  cursor: pointer;
}

.wrap8 {
  height: 70px;
}
.wrap8 a {
  display: flex;
  align-items: center;
}
.wrap8 .txt12 {
  font-size: 13px;
  padding-left: 5px;
}
.txt10 {
  height: 30px;
  padding-right: 14px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 30px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 30px;
}
.wrap8 a:hover .txt12{
  text-decoration: underline;
}
.wrap8 .txt10{
  color: #83FFCB;
}

.info12 {
  width: 60px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 24px;
  font-family: Santral-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin-top: 16px;
  display: block;
}

.pic5 {
  width: 237px;
  height: 1px;
  margin-top: 46px;
}

.TextGroup34 {
  height: 70px;
  margin-top: 57px;
  width: 100%;
  text-align: right;
}

.box23 {
  /* width: 182px; */
  height: 70px;

}

.word18 {
  /* width: 68px; */
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 30px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 30px;
  display: block;
}

.word19 {
  /* width: 182px; */
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 24px;
  font-family: Santral-Medium;
  text-align: right;
  white-space: nowrap;
  line-height: 24px;
  margin-top: 16px;
  display: block;
}

.bd6 {
  background-color: rgba(38, 14, 62, 1);
  border-radius: 50%;
  height: 361px;
  width: 361px;
}

.group6 {
  border-radius: 50%;
  height: 319px;
  border: 1px solid rgba(130, 224, 232, 0.35);
  width: 319px;
  margin: 21px 0 0 21px;
}

.mod10 {
  border-radius: 50%;
  height: 261px;
  border: 1px solid rgba(130, 224, 232, 0.25);
  width: 261px;
  margin: 29px 0 0 29px;
}

.outer11 {
  border-radius: 50%;
  height: 189px;
  border: 1px solid rgba(130, 224, 232, 0.15);
  width: 189px;
  margin: 36px 0 0 36px;
}

.group7 {
  border-radius: 50%;
  width: 125px;
  height: 125px;
  border: 1px solid rgba(130, 224, 232, 0.07);
  margin: 32px 0 0 32px;
}

.mod11 {
  width: 240px;
  height: 117px;
  margin-top: 14px;
}

.TextGroup35 {
  height: 70px;
  margin-left: 44px;
  /* width: 196px; */
}

.outer12 {
  /* width: 196px; */
  height: 70px;
}

.word20 {
  /* width: 125px; */
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 30px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 30px;
  margin-left: 0px;
  display: block;
}

.word21 {
  width: 196px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 24px;
  font-family: Santral-Medium;
  text-align: right;
  white-space: nowrap;
  line-height: 24px;
  margin-top: 16px;
  display: block;
}

.pic6 {
  width: 237px;
  height: 1px;
  margin-top: 46px;
}

.mod12 {
  width: 400px;
  height: 60px;
  margin: 37px 0 35px 392px;
}

.outer8 {
  height: 60px;
  width: 400px;
  cursor: pointer;
  background:#5643F2 !important;
  border-radius: 50px!important;
}
.outer8:hover{
  background:linear-gradient(135deg, #83FFCB 0%, #82C9FD 100%)!important
}


.ImageText12 {
  height: 24px;
}

.mod13 {
  height: 24px;
}

.TextGroup36 {
  height: 24px;
  width: 138px;
}

.txt17 {
  width: 138px;
  height: 24px;
  overflow-wrap: break-word;
  color: #fff;
  font-size: 24px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 24px;
  display: block;
}
.outer8:hover .txt17 {
  color: #260E3E;
}
.outer8:hover .layer27 {
  border-color: #260E3E;
}

.outer8 {
  position: relative;
}
.layer27 {
  /* width: 17px;
  height: 10px;
  margin-top: 7px; */

  display: inline-block;
  content: " ";
  height: 14px;
  width: 14px;
  border-width: 0 2px 2px 0;
  border-color: #fff;
  border-style: solid;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  transform-origin: center;
  transition: transform .3s;
  position: absolute;
  top: 50%;
  right: 115px;
  margin-top: -10px;
}

.outer4 {
  z-index: 305;
  height: 343px;
  background: url(./img/SketchPngdb51bce5edf0f2baade0e0321960925d3e36f3453cb752457870f2a8e26f0622.png)
    100% no-repeat;
  width: 401px;
  position: absolute;
  left: 392px;
  top: 197px;
  align-items: center;
}
.isScheduled.outer4 {
  background: url(./images/circle.png)
    100% no-repeat;
  background-size: cover;
}

.bd16 {
  /* width: 276px; */
  height: 142px;
  margin-top: 107px;
}

.info14 {
  width: 158px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(131, 255, 203, 1);
  font-size: 20px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  display: block;
}
.isScheduled .info14 {
  color: #4F66FF;;
}

.word24 {
  /* width: 276px; */
  height: 50px;
  overflow-wrap: break-word;
  font-size: 50px;
  text-transform: uppercase;
  font-family: Santral-Bold;
  text-align: center;
  white-space: nowrap;
  line-height: 50px;
  margin-top: 39px;
  color: rgba(131, 255, 203, 1);
}
.isScheduled .word24 {
  color: #4F66FF;
  background: linear-gradient(360deg, #8999FF 0%, #4F66FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.info35 {
  width: 276px;
  height: 50px;
  overflow-wrap: break-word;
  font-size: 50px;
  text-transform: uppercase;
  font-family: Santral-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
}
.isScheduled .word72 {

}
.isScheduled .word73 {
  
}
.isScheduled .info36 {
  
}
.word72 {
  width: 276px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(131, 255, 203, 1);
  font-size: 50px;
  text-transform: uppercase;
  font-family: Santral-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
}

.word73 {
  width: 276px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(131, 255, 203, 1);
  font-size: 50px;
  text-transform: uppercase;
  font-family: Santral-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
}

.info36 {
  width: 276px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(131, 255, 203, 1);
  font-size: 50px;
  text-transform: uppercase;
  font-family: Santral-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
}

.txt33 {
  width: 276px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(131, 255, 203, 1);
  font-size: 50px;
  text-transform: uppercase;
  font-family: Santral-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
}

.box24 {
  width: 254px;
  height: 14px;
  margin: 19px 0 0 25px;
}

.info19 {
  width: 34px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  display: block;
}

.info20 {
  width: 41px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 67px;
  display: block;
}

.txt16 {
  width: 55px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 14px;
  font-family: Santral-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 57px;
  display: block;
}

.layer16 {
  background-color: rgba(87, 69, 241, 1);
  width: 1113px;
  height: 4px;
  margin: 80px 0 0 439px;
}



.box26 {
  width: 1071px;
  height: 465px;
  margin: 55px 0 0 55px;
}

.section14 {
  width: 1059px;
  height: 28px;
  margin-left: 1px;
}

.outer5 {
  background-color: rgba(110, 56, 155, 1);
  width: 430px;
  height: 2px;
  margin-top: 12px;
}

.word26 {
  width: 85px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(131, 255, 203, 1);
  font-size: 28px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 28px;
  margin-left: 70px;
  display: block;
}

.outer6 {
  background-color: rgba(110, 56, 155, 1);
  width: 430px;
  height: 2px;
  margin: 12px 0 0 44px;
}

.section15 {
  width: 1060px;
  height: 25px;
  margin-top: 66px;
}



.info16 {
  width: 83px;
  height: 25px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 25px;
  margin-left: 203px;
}

.info37 {
  width: 83px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.info38 {
  width: 83px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.word74 {
  width: 120px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 18px;
  display: block;
  margin: 4px 0 0 12px;
}

.word75 {
  width: 176px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 24px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin-left: 109px;
  display: block;
}

.group8 {
  width: 83px;
  height: 25px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 25px;
  margin-left: 163px;
}

.word76 {
  width: 83px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.word77 {
  width: 83px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.word78 {
  width: 44px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 18px;
  display: block;
  margin: 4px 0 0 5px;
}

.section16 {
  width: 1069px;
  height: 60px;
  margin-top: 25px;
}

/* .wrap9 {
  background-color: rgba(38, 14, 62, 1);
  border-radius: 30px;
  height: 60px;
  width: 480px;
} */

.outer13 {
  width: 427px;
  height: 28px;
  margin: 16px 0 0 30px;
}

.info39 {
  width: 58px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 24px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 24px;
  margin-top: 4px;
  display: block;
}

.outer14 {
  width: 79px;
  height: 25px;
  overflow-wrap: break-word;
  font-size: 0;
  text-transform: uppercase;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 3px 0 0 23px;
}

.word79 {
  width: 79px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  text-transform: uppercase;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.word80 {
  width: 79px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.word81 {
  width: 79px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  text-transform: uppercase;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.outer15 {
  background-color: rgba(255, 154, 1, 1);
  border-radius: 30px;
  height: 28px;
  margin-left: 161px;
  width: 55px;
}



.info40 {
  width: 37px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  display: block;
  margin: 5px 0 0 14px;
}

.layer38 {
  width: 22px;
  height: 23px;
  background: url(./img/SketchPng295b0f0daa4caeec5df432e1ac9b428c5a036632a48c1d3ea2db7569c629afc7.png)
    100% no-repeat;
  margin-top: 19px;
}

.wrap10 {
  background-color: rgba(38, 14, 62, 1);
  border-radius: 30px;
  height: 60px;
  width: 480px;
}

.group9 {
  width: 434px;
  height: 24px;
  margin: 20px 0 0 27px;
}

.txt34 {
  width: 58px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 24px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 24px;
  display: block;
}

.word83 {
  width: 38px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 1px;
  display: block;
}



.section17 {
  width: 1069px;
  height: 61px;
  margin-top: 36px;
}

.TextGroup37 {
  height: 61px;
  width: 104px;
}

.mod14 {
  width: 104px;
  height: 61px;
}

.info41 {
  width: 93px;
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 30px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 30px;
  display: block;
}

.txt35 {
  width: 104px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 13px;
  display: block;
}

.TextGroup38 {
  height: 61px;
  width: 106px;
}

.main8 {
  width: 106px;
  height: 61px;
}

.txt36 {
  width: 99px;
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(239, 242, 255, 1);
  font-size: 30px;
  text-transform: uppercase;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 30px;
  margin-left: 7px;
  display: block;
}

.word84 {
  width: 106px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(182, 174, 199, 1);
  font-size: 18px;
  font-family: Santral-SemiBold;
  text-align: right;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 13px;
  display: block;
}





.main9 {
  z-index: 7;
  position: absolute;
  left: 0;
  top: 2px;
  width: 1920px;
  height: 2450px;
  overflow: hidden;
  background: url(./img/SketchPngc07f6adfec71b3126284d80845b94d1d484d1a791e1ee57bb6da6f0a549a6dbd.png)
    100% no-repeat;
}

.outer16 {
  z-index: 8;
  position: absolute;
  left: -392px;
  top: 0;
  width: 2704px;
  height: 1803px;
  background: url(./img/SketchPng89196fc5ed7ad42e6cf7ddcbd688ca9793f944db4d6ee1e4e06f16651cba316b.png)
    392px 0px no-repeat;
}
