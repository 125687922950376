.learnMoreBlock {
  margin-top: 72px;
  padding: 60px 50px;
  font-size: 14px;
  color: #F2F5FF;
  /* background: linear-gradient(135deg, rgba(200, 109, 215, 0) 0%, #3023AE 100%) */
  background: linear-gradient(135deg, #2b0d43 0%, #421d8a 100%) !important;
}
.learnMoreBlock .mod12 {
  margin: 50px auto 0;
}
.learnMoreBlock .chakra-stack {
  width: 70%;
  margin-bottom: 40px;
}
.learnMoreBlock a{
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-fast);
  transition-timing-function: var(--chakra-transition-easing-ease-out);
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: #83FFCB;
}
.learnMoreBlock ul {
  padding-left: 32px;
  margin-top: 24px;
  list-style: disc;
}
.learnMoreBlock p {
  margin-top: 24px;
}