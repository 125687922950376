@import 'theme.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/lanhu.css';
@import './assets/lanhu_common.css';
@import './assets/card.css';
@import './assets/learn_more.css';
body {
  @apply text-white font-medium;
  background-color: var(--theme-background-color);
  /* font-family: 'Inter', sans-serif; */
  font-family: "Santral-Medium";
  background-color: rgba(13, 2, 55, 1);
  background-image: url(./assets/images/bg_home.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

.btn-primary {
  @apply text-sm font-bold;
  background-color: var(--theme-primary-button-background-color);
  color: var(--theme-primary-button-text-color);
}

.btn-disabled {
  @apply bg-gray-400 cursor-not-allowed text-black;
}

.text-secondary {
  @apply text-xs;

  opacity: var(--theme-secondary-text-opacity);
}
