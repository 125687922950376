.phase1End {
    width: 1184px;
    height: 505px;
    padding: 48px 54px 0;
    background: #260E3E;
    border: 4px solid #8D58CC;
    margin-bottom: 150px;
    position: relative;
    text-align: center;
}
.phase1End::before {
    content: "";
    width: 100%;
    height: 4px;
    left: 0;
    top: -14px;
    background: #5745F1;
    position: absolute;
}
.phase1End_head {
    text-transform: uppercase;
    font-size: 28px;
    font-family: Santral-SemiBold, Santral;
    font-weight: 600;
    color: #83FFCB;
    line-height: 40px;
    position: relative;
    text-align: center;
}
.phase1End_head::before,.phase1End_head::after {
    width: 360px;
    height: 2px;
    background: #6E389B;
    content: "";
    position: absolute;
    left: 0;
    top: 18px;
    /* transform: translateY(-50%); */
}
.phase1End_head::after {
    right: 0;
    left: auto;
}
.phase1End_content {
    text-align: center;
    height: 20px;
    font-size: 24px;
    font-family: Santral-SemiBold, Santral;
    font-weight: 600;
    color: #F2F5FF;
    line-height: 20px;
    margin-top: 36px;
}
.phase1End_img {
    text-align: center;
    margin-top: 100px;
}
.phase1End_img img {
    height: 128px;
    vertical-align: middle;
    margin: 0 auto;
}