
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.align-start {
  display: flex;
  align-items: flex-start;
}
.align-center {
  display: flex;
  align-items: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
/* // 卡片背景色样式 */
.rct_card {
  border-radius: 0 !important;
  /* width: 100% !important; */
  background: linear-gradient(135deg, #2b0d43 0%, #421d8a 100%) !important;
  border: 4px solid #8D58CC !important;
  position: relative;
}
.rct_card::before {
  content: "";
  position: absolute !important;
  width: 100% !important;
  height: 4px !important;
  background: #5745F1 !important;
  top: -14px !important;
  left: 0 !important;
}
/* css变量 */
:root {
  --chakra-transition-property-common: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  --chakra-transition-duration-fast: 150ms;
  --chakra-transition-easing-ease-out: cubic-bezier(0, 0, 0.2, 1);
}