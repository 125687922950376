.swap-card-overlay {
  @apply px-12 pt-16 pb-8 flex justify-center items-center flex-col backdrop-filter backdrop-blur-sm transition-colors duration-300 ease-out;
}

.swap-card-overlay h3 {
  @apply text-lg;
  height: 20px;
font-size: 28px;
font-family: Santral-SemiBold, Santral;
font-weight: 600;
color: #Fff;
line-height: 20px;
}
.swap-card-overlay {
  background: rgba(52, 22, 90, 0.95);
  box-shadow: 4px 4px 6px 3px rgba(25, 7, 78, 0.3);
}
.swap-card-overlay--waitingForExtension {
  /* background-color: var(--theme-swap-card-overlay-waitingForExtension-background-color); */
}

.swap-card-overlay--pending {
  /* background-color: var(--theme-swap-card-overlay-pending-background-color); */
}

.swap-card-overlay--complete {
  /* background-color: var(--theme-swap-card-overlay-complete-background-color); */
}

.swap-card-overlay--error h3{
  /* background-color: var(--theme-swap-card-overlay-error-background-color); */
  color: #FC1355;
}

.swap-card-overlay button {
  @apply text-sm rounded-lg w-full font-bold py-2;
}
.swap-card-overlay img {
  height: 186px;
}
.swap-card-overlay button {
  /* background-color: var(--theme-swap-card-overlay-complete-continue-button-background-color); */
  margin-top: 15px;
  background: #5A30AE;
  width: 370px;
  height: 40px;
  font-size: 16px;
  font-family: Santral-Medium, Santral;
  font-weight: 500;
  color: #F7EFFF;
  border-radius: 20px!important;
}
.swap-card-overlay button:hover{
  background: linear-gradient(135deg, #83FFCB 0%, #82C9FD 100%)!important;
    color: #260E3E;
}

.swap-card-overlay--error button {
  /* background-color: var(--theme-swap-card-overlay-error-continue-button-background-color); */
}
